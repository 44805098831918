<template>
  <v-row>
    <v-col cols="12">
      <apollo-item
        v-for="apollo in apolloClientsList"
        :key="apollo._id"
        :apollo="apollo"
      />
      <v-progress-linear
        v-if="loading"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      />
    </v-col>
  </v-row>
</template>

<script>
  import ApolloItem from '@/components/apolloClientsPage/ApolloItem';
  import { mapGetters } from 'vuex';
  import { lazyLoadMixin } from '@/mixins/lazyLoadMixin';

  export default {
    name: 'CredentialsPage',
    components: {
      ApolloItem,
    },
    mixins: [lazyLoadMixin],
    computed: {
      ...mapGetters({
        apolloClientsList: 'apollo/apolloClientsList',
      }),
    },
    methods: {
      handleScroll: async function () {
        const bottomOfWindow =
          document.documentElement.scrollTop.toFixed() + window.innerHeight.toFixed() >=
          document.documentElement.offsetHeight;
        if (bottomOfWindow && this.canLoadMore && !this.loading) {
          this.loading = true;
          const count = this.apolloClientsList.length;
          await this.$store.dispatch('apollo/LOAD_CLIENTS_LIST', count);
          this.canLoadMore = this.apolloClientsList.length > count;
          this.loading = false;
        }
      },
    },
  };
</script>

<style scoped></style>
